<template>
    <div>
        <div class="form-row">
            <div class="form-col">
                <label>content alignment</label>
                <div class="radio-group mt-3">
                    <div class="radio">
                        <input type="radio" name="alignment" id="alignmentLeft" value="left" v-model="hero.alignment">
                        <label for="alignmentLeft">
                            <icon name="alignLeft" class="w-5 h-5 text-black"></icon>
                        </label>
                    </div>
                    <div class="radio">
                        <input type="radio" name="alignment" id="alignmentCenter" value="center" v-model="hero.alignment">
                        <label for="alignmentCenter">
                            <icon name="alignCenter" class="w-5 h-5 text-black"></icon>
                        </label>
                    </div>
                    <div class="radio">
                        <input type="radio" name="alignment" id="alignmentRight" value="right" v-model="hero.alignment">
                        <label for="alignmentRight">
                            <icon name="alignRight" class="w-5 h-5 text-black"></icon>
                        </label>
                    </div>
                </div>
                <!-- <toggle-buttons :options="alignments" v-model="hero.alignment"></toggle-buttons> -->
            </div>
            <div class="form-col">
                <label for="headline">headline</label>
                <text-input
                  id="headline"
                  v-model="hero.headline"
                  autocomplete="off"
                />
                <span v-if="hasError('hero.headline')" class="form-error">{{ showError('hero.headline') }}</span>
            </div>
        </div>
        <div class="form-row">
            <div class="form-col">
                <label>media width</label>
                <toggle-buttons :options="widths" v-model="hero.mediaWidth"></toggle-buttons>
            </div>
            <div class="form-col">
                <label for="subHeadline">sub-headline</label>
                <text-input
                  id="subHeadline"
                  v-model="hero.subHeadline"
                  autocomplete="off"
                />
            </div>
        </div>
        <div class="form-row">
            <div class="form-col">
                <label>Video url</label>
                <input type="text" class="form-input" id="video" autocomplete="off"  v-model="hero.video"/>
                <div class="radio-group">
                    <h1 class="check-box">Mute</h1>
                    <input class="check-box" style="margin-left: 1rem;" type="checkbox" v-model="hero.checked" id="checked">                    
                </div>
                <a>Only support youtube.com videos</a>
                <span v-if="hasError('hero.video')" class="form-error">{{ showError('hero.video') }}</span>
            </div>
            <div class="form-col">
                <label for="buttonText" >button text</label>
                <input type="text" class="form-input" id="buttonText" autocomplete="off"  v-model="hero.buttonText"/>
                <label class="mt-6">button link</label>
                <url-input v-model="hero.buttonLink" :website-id="websiteId"></url-input>
            </div>
        </div>

        <modal-footer :primary="save"></modal-footer>
    </div>
</template>

<script>
    import Widget from "@/mixins/Widget";
    import UrlInput from "@/components/cms/UrlInput";
    import ModalNavigation from "@/mixins/ModalNavigation";
    import ToggleButtons from "@/components/ui/ToggleButtons";
    import ModalFooter from "@/components/ui/modals/ModalFooter";
    import Icon from "@/components/ui/Icon";
    import TextInput from "@/components/ui/TextInput";
    import NotifyMixin from "@/mixins/NotifyMixin";

    export default {
        mixins: [ ModalNavigation, Widget, NotifyMixin ],
        components: { ModalFooter, ToggleButtons, UrlInput, Icon, TextInput },
        emits: ['save-page-widget'],
        data: () => {
            return {
                alignments: [
                    { value: 'left', label: 'left'},
                    { value: 'center', label: 'center'},
                    { value: 'right', label: 'right'},
                ],
                widths: [
                    { value: 'full-width', label: 'full-width'},
                    { value: 'contained', label: 'contained'},
                ],
                hero: {
                    alignment: 'left',
                    headline: null,
                    subHeadline: null,
                    mediaWidth: 'full-width',
                    video: null,
                    buttonText: null,
                    buttonLink: null,
                    checked: false,
                },
                websiteId: null,
                isProcessing: false,
                IDVideo: null,
                muteValue: 0,
            }
        },
        methods: {
            save() {     
                if(this.hero.checked == true){
                    this.muteValue = 1
                }
                if(this.validator.passes()) {
                    try{
                        let url = this.hero.video;
                        let regExp = /^(?:https?:\/\/|)(?:www\.youtube(?:-nocookie)?\.com\/|m\.youtube\.com\/|youtube\.com\/)?(?:ytscreeningroom\?vi?=|youtu\.be\/|vi?\/|user\/.+\/u\/\w{1,2}\/|embed\/|watch\?(?:.*&)?vi?=|&vi?=|\?(?:.*&)?vi?=)([^#&?\n/<>"']*)/i;
                        let match = url.match(regExp);
                        this.IDVideo = 'https://www.youtube.com/embed/' + match[1] + '?controls=1&autoplay=1&loop=1&fs=0&rel=0&showinfo=0&modestbranding=1&mute='+ this.muteValue +'&playlist=' + match[1];
                    }catch (e) {
                        this.notifyError('Fail to push the URL video', e)
                    }
                    this.isProcessing = true;
                    this.$emit('save-page-widget', Object.assign({
                        alignment: this.hero.alignment,
                        buttonLink: this.hero.buttonLink,
                        buttonText: this.hero.buttonText,
                        headline: this.hero.headline,
                        mediaWidth: this.hero.mediaWidth,
                        subHeadline: this.hero.subHeadline,
                        video: this.IDVideo,
                        checked: this.hero.checked
                        }));
                }
            }
        },
        validations: {
            'hero.video' : 'required | url',
        },
        created() {
            this.initValidator();
            this.websiteId = this.$route.params.websiteId;
            if (parseInt(this.getValue('checked')) == 0){
                this.hero.checked = false
            }else{
                this.hero.checked = true
            }
            if (this.values.length) {
                Object.assign(this.hero, {
                    headline: this.getValue('headline'),
                    subHeadline: this.getValue('subHeadline'),
                    alignment: this.getValue('alignment'),
                    mediaWidth: this.getValue('mediaWidth'),
                    video: this.getValue('video'),
                    buttonText: this.getValue('buttonText'),
                    buttonLink: this.getValue('buttonLink'),
                    checked:  this.hero.checked
                });
            }
        }
    }
</script>
<style scoped>
    .radio-group{
        display:flex;
        align-items: center;
    }
    .radio-group .radio + .radio{
        margin-left: 1.5rem;
    }
    .radio label{
        display:flex;
        align-items: center;
    }
    .radio input{
        margin-right: .5rem;
    }
    .check-box{
        font-size: 0.920rem;
        --text-opacity: 1;
        font-weight: 600;
        color: rgba(0, 0, 0, var(--text-opacity));
    }
</style>